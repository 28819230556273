import React from 'react';
import PropTypes from 'prop-types';
import style from './style';

export default function Title(props) {
  return (
    <h1 className={style.header}>{props.children}</h1>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};
