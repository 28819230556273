/* eslint-disable camelcase, max-len */
import * as React                      from 'react';
import PropTypes                       from 'prop-types';
import Helmet                          from 'react-helmet';
import partPhoto                       from '../../utils/partPhoto';
import AppConfig, { connectAppConfig } from 'src/shared/components/AppConfig';

class Meta extends React.PureComponent {
  static propTypes = {
    page:   PropTypes.oneOf(['front', 'catalog', 'static', 'news']),
    params: PropTypes.shape(),
    title:  PropTypes.string,
  };

  metaConfig = {
    front:   {
      title:       `Автозапчасти б/у для иномарок. Интернет-магазин автозапчастей - ${this.props.config.TITLE}`,
      keywords:    `автозапчасти, запчасти, иномарки, альфадетали, ${this.props.config.TITLE}`,
      description: `${this.props.config.TITLE} - крупнейший интернет-магазин б/у автозапчастей. Запчасти для легковых и грузовых автомобилей иностранного и отечественного производства. Широкий ассортимент, гибкие цены, гарантия, доставка, пункты выдачи в 533 городах России.`,
    },
    catalog: ( { brand_en, model_en, generation_en, brand_ru, model_ru, generation_ru, part_group, part_name } ) => {
      let car_en = brand_en || '';
      if (generation_en) {
        car_en += ` ${generation_en}`;
      } else if (model_en) car_en += ` ${model_en}`;

      let car_ru = '';
      if (brand_ru || model_ru || generation_ru) {
        car_ru += brand_ru || brand_en;
        if (generation_ru) {
          car_ru += ` ${generation_ru}`;
        } else if (generation_en) {
          car_ru += ` ${generation_en}`;
        } else if (model_ru) {
          car_ru += ` ${model_ru}`;
        } else if (model_en) car_ru += ` ${model_en}`;
      }

      const part = part_name || part_group || 'запчасти';

      return {
        title:       `${car_en ? `${car_en} - ` : ''}б/у ${part}. Интернет-магазин автозапчастей${car_ru ? ` ${car_ru}` : ''} - ${this.props.config.TITLE}`,
        keywords:    `${car_en || ''}${car_ru ? `, ${car_ru}` : ''}${car_en ? `, ${part} ${car_en}` : ''}${car_ru ? `, ${part} ${car_ru}` : ''}`,
        description: `Каталог автозапчастей${car_en ? ` ${car_en}` : ''}. Б/у ${part}${car_ru ? ` для ${car_ru}` : ''} в наличии. Подробные фотографии. Гарантия, доставка по России.`,
      };
    },
    part:    ( params ) => {
      const { breadcrumbs, part: { barcode, kod } } = params;
      const {
              brand_en,
              model_en,
              generation_en,
              brand_ru,
              model_ru,
              generation_ru,
              part_group,
              part_name,
            } = breadcrumbs;

      let car_en = brand_en || '';
      if (generation_en) {
        car_en += ` ${generation_en}`;
      } else if (model_en) car_en += ` ${model_en}`;

      let car_ru = '';
      if (brand_ru || model_ru || generation_ru) {
        car_ru += brand_ru || brand_en;
        if (generation_ru) {
          car_ru += ` ${generation_ru}`;
        } else if (generation_en) {
          car_ru += ` ${generation_en}`;
        } else if (model_ru) {
          car_ru += ` ${model_ru}`;
        } else if (model_en) car_ru += ` ${model_en}`;
      }

      const part = part_name || part_group || 'запчасти';

      return {
        title:       `${car_en ? `${car_en} - ` : ''}б/у ${part}. Артикул ${barcode || 'n/a'}, Оригинальный номер: ${kod || 'n/a'}. ${this.props.config.TITLE}`,
        keywords:    `${car_en || ''}${car_ru ? `, ${car_ru}` : ''}${car_en ? `, ${part} ${car_en}` : ''}${car_ru ? `, ${part} ${car_ru}` : ''}`,
        description: `Каталог автозапчастей${car_en ? ` ${car_en}` : ''}. Б/у ${part}${car_ru ? ` для ${car_ru}` : ''}, артикул ${barcode || 'n/a'}, Оригинальный номер: ${kod || 'n/a'}, в наличии. Подробные фотографии. Гарантия, доставка по России.`,
      };
    },
    static:  {
      title:       ( { title } ) => title,
      keywords:    ( { keywords } ) => keywords,
      description: ( { description } ) => description,
    },
    news:    {
      title:            ( { title } ) => title,
      keywords:         ( { keywords } ) => keywords,
      description:      ( { description } ) => description,
      'og:title':       ( { title } ) => title,
      'og:description': ( { shortText } ) => shortText,
      'og:image':       ( { image } ) => partPhoto(image, '730x437', this.props.config.PHOTO_TOKEN),
    },
  };

  getMeta = ( page, params ) => {
    const metaPage = this.metaConfig[page];
    const meta = typeof metaPage === 'function' ? metaPage(params) : metaPage;

    return ( type ) => {
      if (!meta) return '';
      const value = meta[type];
      if (!value) return '';
      return typeof value === 'function' ? value(params) : value;
    };
  };

  getTag = ( title, meta ) => ( name ) => {
    if (title) return null;
    const value = meta(name);
    if (!value) return null;
    return <meta name={name} content={value} />;
  };

  render() {
    const { config } = this.props;
    const { page, title, params } = this.props;
    const meta = this.getMeta(page, params);
    const tag = this.getTag(title, meta);
    return (
      <Helmet>
        <title>{title ? `${title} - ${config.TITLE}` : meta('title')}</title>
        {tag('description')}
        {tag('keywords')}
        {tag('og:title')}
        {tag('og:description')}
        {tag('og:image')}
      </Helmet>
    );
  }
}

export default connectAppConfig(Meta);
