import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import style from './style';
import { catalogPage } from '../../../utils/links';

class BrandListItem extends Component {
  static propTypes = {
    brand: PropTypes.shape({
      id:            PropTypes.number,
      title:         PropTypes.string,
      titleTranslit: PropTypes.string,
      cargo:         PropTypes.bool,
      isPopular:     PropTypes.bool,
    }),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      small: false,
    };
  }

  render() {
    const { brand } = this.props;
    const carType = brand.cargo ? 'trucks' : 'cars';
    return (
      <Link to={catalogPage({ carType, brand: brand.titleTranslit })} className={style.item}>
        <div className={style.icon_wrapper}>
          <div className={classNames(style.icon, carType, `${carType}-${brand.titleTranslit}`)} />
        </div>
        <div className={style.name}>
          <div className={classNames(style.text, { [style.small]: this.state.small })}>{brand.title}</div>
        </div>
      </Link>
    );
  }
}

export default BrandListItem;
