import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import style from './style';

class ButtonGroup extends Component {
  static propTypes = {
    values:   PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      link:  PropTypes.string,
    })),
    value:    PropTypes.string,
    onChange: PropTypes.func,
    huge:     PropTypes.bool,
  };

  static defaultProps = {
    values:   [],
    onChange: () => {},
    huge:     false,
  };

  click(value) {
    if (value && value.value !== this.props.value) {
      this.props.onChange(value);
    }
  }


  render() {
    const { values, huge } = this.props;
    const wrapperClasses = classNames(
      style.wrapper,
      huge ? style.wrapper_huge : style.wrapper_small
    );

    const generateButton = (option, i) => {
      const btnClasses = classNames(
        style.btn,
        (huge) ? style.btn_huge : style.btn_small,
        { [style.btn_active]: this.props.value === option.value }
      );

      let Tag;
      const props = {
        onClick:   () => this.click(option),
        className: btnClasses,
      };
      if (option.link) {
        Tag = Link;
        props.to = option.link;
      } else {
        Tag = 'button';
      }

      return huge
        ? (
          <div key={i} className={classNames(style.btn_wrapper, style.btn_wrapper_huge)}>
            <Tag {...props}>
              {option.label}
            </Tag>
          </div>
        ) : (
          <div key={i} className={classNames(style.btn_wrapper, style.btn_wrapper_small)}>
            <Tag {...props}>
              {option.label}
            </Tag>
          </div>
        );
    };

    return huge
      ? (
        <div className={style.container}>
          <div className={wrapperClasses}>
            {values.map(generateButton)}
          </div>
        </div>
      ) : (
        <div className={wrapperClasses}>
          {values.map(generateButton)}
        </div>
      );
  }
}

export default ButtonGroup;
