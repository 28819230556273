import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './style';
import * as propTypes from '../../../propTypes';
import { to } from '../../../utils/scroll';

import BrandListItem from '../BrandListItem';

class BrandList extends Component {
  static propTypes = {
    brands:      propTypes.brands,
    onlyPopular: PropTypes.bool,
  };

  static defaultProps = {
    brands: [],
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showAll: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  list() {
    return (!this.props.onlyPopular || this.state.showAll)
      ? this.props.brands
      : this.props.brands.filter(b => b.isPopular);
  }

  toggle() {
    this.setState({ showAll: !this.state.showAll });
    to(0);
  }

  render() {
    return (
      <div>
        <div className={style.wrapper}>
          <div className={style.list}>
            {this.list().map(brand => <BrandListItem key={brand.id} brand={brand} />)}
          </div>
        </div>
        {this.props.onlyPopular && (
          <div onClick={this.toggle} className={style.all_link}>
            {this.state.showAll ? 'Свернуть' : 'Все марки'}
          </div>
        )}
      </div>
    );
  }
}

export default BrandList;
